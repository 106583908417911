html,
body {
  background-color: #cad4e0;
}

.App {
  text-align: center;
}

#kalender .rmdp-shadow {
  box-shadow: none;
}

#kalender .rmdp-calendar {
  width: 100%;
  height: max-content;
  filter: drop-shadow(1px 1px 6px rgb(205, 205, 205));
  background: white;
  margin-top: 0px;
  border-radius: 5px;
  padding: 0px;
}

#kalender .rmdp-wrapper {
  width: 100%
}

#kalender .rmdp-header {
  background: #51242c;
  padding: 10px;
  border-radius: 5px 5px 0px 0px;
  margin-top: 0px;
}

#kalender .rmdp-header .rmdp-arrow {
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  height: 3px;
  margin-top: 5px;
  padding: 2px;
  width: 3px;
}

#kalender .rmdp-header span {
  color: white;
}

.rmdp-day-picker div {
  width: 100%;
}

.rmdp-day-picker .rmdp-day span {
  border-radius: 10%;
}

.rmdp-week .rmdp-week-day {

  color: #a52727;
  cursor: default;
  font-size: 16px;
  font-weight: 500;
  font-variant: all-petite-caps;

}

.rmdp-calendar .rmdp-day.rmdp-deactive,
.rmdp-calendar .rmdp-day.rmdp-disabled {
  color: #cecece;
}

.red .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #7e1d32;
  box-shadow: 0 0 3px var(--rmdp-shadow-red);
}


.rmdp-day-picker .rmdp-week .rmdp-day span:hover {
  background-color: #bd9797;
  color: #fff;
}


.rmdp-calendar .rmdp-day-picker .rmdp-week .rmdp-disabled span:hover {
  background-color: transparent;
  color: #cecece;
}

.rmdp-week .rmdp-day.rmdp-today span {
  background-color: #dddddd;
  color: #000000;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.image-upload>input {
  display: none;
}

.image-upload img {
  width: 80px;
  cursor: pointer;
}

.chat {
  max-height: 60%;
}

.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}

.swal-text {
  padding: 17px;
  display: block;
  margin: 0px 71px;
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sideActive {
  background-color: #ca9510;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@media (max-width: 768px) {
  .sidebar-mobile {
    left: -250px;
  }
}